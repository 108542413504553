<template>
  <div
    data-app="true"
    class="application"
  >
    <div
      v-if="!inMaintenance"
    >
      <CustomButton
        v-if="isEnabled && isButtonVisible"
        class="aiquery-start-conversation"
        small
        label="✨"
        @on-click="handleButtonClick"
      />

      <CustomModal
        class="aiquery-modal"
        :title="title"
        :show-modal="modalOpen"
        @close-modal="closeModal"
      >
        <QueryResponseTable
          v-if="queryResponse.length"
          :query-response="queryResponse"
          @close-results="closeResults"
        />
        <div
          v-if="!queryResponse.length"
          class="search"
        >
          <div class="search-field">
            <Field
              id="search-input"
              v-model="search"
              type="text"
              :input-ref="searchbox"
              :twoway="true"
              :placeholder="t('What do you want to achieve today?')"
            />
            <CustomButton
              v-if="enableSpeechToText"
              small
              :label="recording ? '🛑' : '✨'"
              @on-click="toggleSpeech"
            />
          </div>

          <FunFactor
            v-if="hasFunQuestion"
          />

          <div
            v-show="intents.length === 0 && queryResponse.length === 0"
            class="example-inputs"
          >
            <h4>{{ t('Or use one of the examples below') }}</h4>
            <p>
              <CustomButton
                purpose="text"
                @on-click="setSearchValue(t('What are the top products from the last week?'))"
              >
                {{ t('What are the top products from the last week?') }}
              </CustomButton>
            </p>
            <p>
              <CustomButton
                purpose="text"
                @on-click="setSearchValue(t('What are my top sales people from the last month?'))"
              >
                {{ t('What are my top sales people from the last month?') }}
              </CustomButton>
            </p>
            <p>
              <CustomButton
                purpose="text"
                @on-click="setSearchValue(t('Who are my best customers from the last year?'))"
              >
                {{ t('Who are my best customers from the last year?') }}
              </CustomButton>
            </p>
            <p>
              <CustomButton
                purpose="text"
                @on-click="setSearchValue(t('Who are my lowest performing customers from last week?'))"
              >
                {{ t('Who are my lowest performing customers from last week?') }}
              </CustomButton>
            </p>
            <p>
              <CustomButton
                purpose="text"
                @on-click="setSearchValue(t('Who should I promote today?'))"
              >
                {{ t('Who should I promote today?') }}
              </CustomButton>
            </p>
          </div>
          <div
            v-show="intents.length && !hasFunQuestion"
            class="list"
            role="list"
          >
            <CRMCard
              v-for="intent in intents"
              :key="intent.id"
              role="listitem"
              :chips="[
                {
                  value: (intent.confidence_score * 100).toFixed(0),
                  suffix: '%',
                  amber: 33,
                  green: 67,
                }
              ]"
              :links="getLinks(intent)"
              @go-to-enquiry="goToEnquiry"
              @view-data="viewData"
            >
              <template #info>
                <h3>{{ intent.display_name }}</h3>
                {{ getSummaryText() }}
              </template>

              <template #tags>
                <div class="feedback">
                  <p>{{ t('Is this result useful') }}?</p>
                  <IconButton
                    purpose="transparent"
                    icon-name="check"
                    icon-color="var(--colour-brand-de-york)"
                    @on-click="() => showPositiveFeedbackModal(intent.intent_category)"
                  />
                  <IconButton
                    purpose="transparent"
                    icon-name="close"
                    icon-color="var(--colour-brand-mandy)"
                    @on-click="() => showNegativeFeedbackModal(intent.intent_category)"
                  />
                </div>
              </template>
            </CRMCard>
          </div>
        </div>
      </CustomModal>

      <FeedbackModal
        :query-id="queryId"
        :show-modal="feedbackModalOpen"
        :positive-feedback="feedbackPositive"
        :feedback-intent-category="feedbackIntentCategory"
        @close-modal="closeFeedbackModal"
      />

      <ModalQueue :items="modalQueue.data" />
    </div>
  </div>
</template>

<script setup>
// Constants
import { computed, ref, watch, onMounted, onBeforeUnmount } from 'vue';
import { useStore } from 'vuex';
import { debounce } from '@/shared/utils/debounce';
import { SET_ITEMS, AI_QUERY_CONVERSE, AI_QUERY_METHOD, AI_CLEAR_QUERY_RESPONSE } from '@/shared/store/actionType';
import { SpeechRecognitionService, canUseSpeechToText } from '@/shared/utils/speechRecognition';
import { dates, t, userDetailsState } from '@sales-i/utils';
import { capitalize } from '@/shared/utils/strings';
import { 
  baseUrl,
  insightsScope,
  productsScope,
  baseCrmUrl,
  interactionsScope,
  communitiesScope,
  customerScope,
} from '@/router/urlBits';
import {
  HIGHEST_SELLING_PRODUCTS,
  LOWEST_SELLING_PRODUCTS,
  HIGHEST_REVENUE_CUSTOMER,
  LOWEST_REVENUE_CUSTOMER,
} from '@/shared/reportTypes';

// Components
import { CustomButton, CustomModal, CRMCard, IconButton } from '@sales-i/dsv3';
import Field from '@/components/Form/Field.vue';
import QueryResponseTable from '@/components/Query/QueryResponseTable.vue';
import ModalQueue from '@/components/ModalQueue.vue';
import FunFactor from '@/components/FunFactor/Fun.vue';
import FeedbackModal from '@/components/Feedback/FeedbackModal.vue';
import { navigateToUrl } from 'single-spa';

const store = useStore();
const formatDate = dates.format;
const inMaintenance = computed(() => store.state.system.maintenance_mode);
const queryResponse = computed(() => store.state.conversation.queryResponse || []);
const intents = computed(() => store.getters['conversation/getAllOrderedIntents'] || []);
const entities = computed(() => store.state.conversation.response.entities || []);
const queryId = computed(() => store.state.conversation.response.id || 0);
const modalQueue = computed(() => store.state.modalQueue);
const hasFunQuestion = computed(() => store.getters['conversation/hasFunQuestion'] || false);
const enableSpeechToText = computed(() => canUseSpeechToText());

const modalOpen = ref(false);
const feedbackModalOpen = ref(false);
const feedbackPositive = ref(false);
const feedbackIntentCategory = ref('');
const recording = ref(false);
const search = ref('');
const isEnabled = ref(false);
const title = ref(t('sales-ai spark'));
const isButtonVisible = ref(false);

userDetailsState.subscribe(({ data }) => {
  isEnabled.value = Object.keys(data).length > 0 ? true : false;
});

const converse = (prompt) => store.dispatch(`conversation/${AI_QUERY_CONVERSE}`, prompt);
const converseMethod = (body) => store.dispatch(`conversation/${AI_QUERY_METHOD}`, body);
const clearConverseMethod = () => store.dispatch(`conversation/${AI_CLEAR_QUERY_RESPONSE}`);

onMounted(() => {
  window.addEventListener('scroll', handleScroll);
});

onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleScroll);
});

watch(() => search.value, debounce((value) => {
  search.value = value;
  converse(value);
}, 500));

watch(() => modalOpen.value, debounce(() => {
  const searchInput = document.getElementById('search-input');
  searchInput?.focus();
}, 250));

const setSearchValue = (event) => {
  search.value = event;
};

const showPositiveFeedbackModal = (intent) => {
  showFeedbackModal('positive', intent);
};

const showNegativeFeedbackModal = (intent) => {
  showFeedbackModal('negative', intent);
};

const showFeedbackModal = (type, intent) => {
  switch (type) {
  case 'positive':
    feedbackPositive.value = true;
    break;
  default: 
    feedbackPositive.value = false;
    break;
  }
  feedbackModalOpen.value = true;
  feedbackIntentCategory.value = intent;
  modalOpen.value = false;
};

const closeFeedbackModal = () => {
  feedbackModalOpen.value = false;
  modalOpen.value = true;
};

const onSpeechResult = (event) => {
  const { results } = event;

  search.value = results[0][0].transcript;
};

const handleButtonClick = () => {
  modalOpen.value = true;
};

const closeModal = () => {
  if (recording.value) {
    recording.value = false;
    speech.recognition.stop();
  }
  modalOpen.value = false;
};

const closeResults = () => {
  title.value = t('sales-ai spark');
  clearConverseMethod();
};

const getIntentDirection = () => {
  // We can sum over the intents and determine the probability.
  // As we have a heads or tails on whether the sentiment is good or not.
  let n = 0;
  let probability = 0;
  for (n; n < entities.value.length; n++) {
    const _ref = entities.value[n];
    if (_ref.category !== 'Comparison') {
      continue;
    }
    if (['positive'].includes(_ref.sentiment.sentiment)) {
      probability++;
    } else if (['neutral'].includes(_ref.sentiment.sentiment)) {
      continue;
    } else {
      probability--;
    }
  }

  // Double check, if probability is neutral, what's the contents of the word
  n = 0;
  if (probability === 0) {
    for (n; n < entities.value.length; n++) {
      const _ref = entities.value[n];
      if (_ref.category !== 'Comparison') {
        continue;
      }
      if (
        ['lowest', 'worst', 'low', 'bad', 'sack'].includes(_ref.text.toLowerCase())
      ) {
        probability--;
      } else {
        probability++;
      }
    }
  }

  return probability >= 0 ? 'DESC' : 'ASC';
};

const getSummaryText = () => {
  if (entities.value.length === 0) {
    return '';
  }

  const strings = [];

  // Loop over the entities and build a string
  // to show we understood what the person has said
  let n = 0;
  for (n; n < entities.value.length; n++) {
    let x = 0;
    const _ref = entities.value[n];
    if (_ref.extraInformation === null) {
      strings.push(capitalize(_ref.text));
      continue;
    }

    if (_ref.resolutions === null) {
      continue;
    }

    switch (_ref.extraInformation[x].value) {
    case 'datetime.date':
      // Grab the resolutions and pass them into date parsing
      strings.push(
        t('On %datetime_1%', 'on_datetime', {
          interpolations: {
            datetime_1: formatDate(_ref.resolutions[x].value),
          },
        })
      );
      break;
    case 'datetime.daterange':
      // Grab the resolutions and pass them into date parsing
      strings.push(
        t('For between %datetime_1% and %datetime_2%', 'for_between_datetime_and_datetime', {
          interpolations: {
            datetime_1: formatDate(_ref.resolutions[x].begin),
            datetime_2: formatDate(_ref.resolutions[x].end),
          },
        })
      );
      break;
    default:
      strings.push(
        t('Where %variable% is %value%', 'where_variable_is_value', {
          interpolations: {
            variable: _ref.category,
            value: _ref.resolutions[x].value
          },
        })
      );
      break;
    }
  }

  return strings.join(', ');
};

const getEntityDates = () => {
  const from = new Date();
  const to = new Date();
  from.setFullYear(from.getFullYear() - 1);

  if (entities.value.length === 0) {
    return [from, to];
  }

  let n = 0;
  for (n; n < entities.value.length; n++) {
    let x = 0;
    const _ref = entities.value[n];
    if (_ref.extraInformation !== null) {
      switch (_ref.extraInformation[x].value) {
      case 'datetime.date':
        return [_ref.resolutions[x].value, _ref.resolutions[x].value];
      case 'datetime.daterange':
        return [_ref.resolutions[x].begin, _ref.resolutions[x].end];
      }
    }
  }

  return [formatDate(from), formatDate(to)];
};

const getQueryProducts = () => {
  let positive = true;
  let rProducts = [];
  let nProducts = [];
  let x = 0;
  for (x; x < entities.value.length; x++) {
    const _ref = entities.value[x];
    if (_ref.category.match(/(BuyingIndicator\d+)/) !== null) {
      positive = ['positive', 'neutral'].includes(_ref.sentiment.sentiment);
    }
    if (_ref.category.match(/(Product[\d+]?)/) !== null) {
      if (positive) {
        rProducts.push(_ref.text);
      } else {
        nProducts.push(_ref.text);
      }
    }
  }
  return [rProducts, nProducts];
};

const getLinks = (intent) => {
  const dates = getEntityDates();
  const direction = getIntentDirection();
  // Determine any dates selected, and the products people
  // selecting/not selecting
  const [rProducts, nProducts] = getQueryProducts();
  let report;
  let queryString = new URLSearchParams();
  if (intent.result_type === 'ENQUIRY') {
    switch (intent.local_function) {
    case 'SellingProducts':
      report = direction === 'DESC' ? HIGHEST_SELLING_PRODUCTS : LOWEST_SELLING_PRODUCTS;
      return [{
        href: `${baseUrl}/${insightsScope}/${productsScope}/${report}?date_from=${dates[0]}&date_to=${dates[1]}`,
        text: t('Go to Enquiry'),
        emit: 'goToEnquiry',
      }];
    case 'ManageInteractions':
      return [{
        href: `${baseCrmUrl}/${interactionsScope}`,
        text: t('Manage Interactions'),
        emit: 'goToEnquiry',
      }];
    case 'DetermineCustomerSpend':
      report = direction === 'DESC' ? HIGHEST_REVENUE_CUSTOMER : LOWEST_REVENUE_CUSTOMER;
      return [{
        href: `${baseUrl}/${insightsScope}/${customerScope}/${report}?date_from=${dates[0]}&date_to=${dates[1]}`,
        text: t('Go to Enquiry'),
        emit: 'goToEnquiry',
      }];
    case 'BuildCampaign':
      if (rProducts.length) {
        rProducts.map(p => queryString.append('prod', p));
      }
      if (rProducts.length) {
        nProducts.map(p => queryString.append('nprod', p));
      }
      return [{
        href: `${baseUrl}/${insightsScope}/${communitiesScope}?date_from=${dates[0]}&date_to=${dates[1]}&${queryString.toString()}`,
        text: t('Build Alert'),
        emit: 'goToEnquiry',
      }];
    default:
      return [];
    }
  }

  return [{
    data: {
      dates,
      direction,
      intent,
      id: intent.id,
    },
    text: t('View'),
    emit: 'viewData'
  }];
};

const viewData = async (details) => {
  await converseMethod([details.data.intent.local_function, details.data]);

  title.value = details.data.intent.display_name;

  if (queryResponse.value.length === 0) {
    store.dispatch(`modalQueue/${SET_ITEMS}`, {
      title: t('No results'),
      message: t('We couldn\'t find any results for your date range, please try another.'),
    });
  }
};

const goToEnquiry = (link) => {
  modalOpen.value = false;
  navigateToUrl(link.href);
};

const toggleSpeech = () => {
  if (recording.value) {
    recording.value = false;
    return speech.recognition.stop();
  }
  recording.value = true;
  return speech.recognition.start();
};

const disableSpeech = () => {
  recording.value = false;
  return speech.recognition.stop();
};

const speech = SpeechRecognitionService({
  onresult: onSpeechResult,
  onend: disableSpeech,
});

let lastScrollY = window.scrollY;

const handleScroll = () => {
  isButtonVisible.value = window.scrollY < lastScrollY;
  lastScrollY = window.scrollY;
};

</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

.aiquery-start-conversation {
  z-index: 10;
  position: fixed;
  bottom: var(--spacing-8);
  right: var(--spacing-2);

  &.hidden {
    opacity: 0;
    pointer-events: none;
  }

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    bottom: var(--spacing-16);
  }
}

.search {
  padding: 0 var(--spacing-2);

  .search-field {
    display: flex;
    height: 45px;

    :deep(.field) {
      width: 90%;
      margin-right: var(--spacing-2);
    }
  }
}

.aiquery-modal {
  .list {
    margin: var(--spacing-2) 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: var(--spacing-2);
  }
}

.example-inputs {
  padding: var(--spacing-2);
}

.feedback {
  align-items: center;
  display: flex;
}
</style>
